import { Hint } from "../../../../../../assets/svg/hint";
import { Check } from "../../../../../../assets/svg/check";

import type { TCartItem } from "./CartItem.types";

import "./CartItem.css";

export const CartItem: TCartItem = ({
  heading,
  subHeading,
  price,
  scoredOutPrice,
  isSubHeadingGreen,
  billingPeriod,
  tooltip,
}) => {
  return (
    <div className="codejet__cart-item">
      <div className="codejet__cart-item__name">
        <h3 className="codejet__typography">{heading}</h3>
        <span className={"codejet__typography" + (isSubHeadingGreen ? " codejet__typography-green" : "")}>
          {subHeading}
        </span>
      </div>
      <div className="codejet__cart-item__right">
        <div className="codejet__cart-item__tooltip">
          <Hint className="codejet__cart-item__hint" />
          {tooltip && (
            <div className="codejet__cart-item__tooltip-content">
              {tooltip.map(({ boldText, regularText }, index) => {
                return (
                  <div key={index} className="codejet__typography codejet__cart-item__tooltip-item">
                    <Check className="codejet__cart-item__tooltip-item__icon" />
                    <strong>{boldText} </strong>
                    <span>&nbsp;{regularText}</span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="codejet__typography codejet__cart-item__container">
          <div className="codejet__typography codejet__cart-item__price">
            <strong>${price}</strong>
            {billingPeriod ? `/${billingPeriod}` : ""}
          </div>
          {scoredOutPrice && (
            <div className="codejet__typography codejet__cart-item__old-price">
              <s>${scoredOutPrice}</s>
              {billingPeriod ? `/${billingPeriod}` : ""}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
