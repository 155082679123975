import { createMachine, type EventObject } from "xstate";
import { Products } from "../../services/Products/Products.ts";

export interface CustomEvent<T> extends EventObject {
  data: T;
}

export const productsMachineInit = (service: Products) =>
  createMachine(
    {
      predictableActionArguments: true,
      context: {
        service,
        isProjectSettings: false,
      },
      id: "PRODUCTS",
      initial: "START",
      states: {
        START: {
          on: {
            PICK_TEMPLATE: "PICK_TEMPLATE",
            SELECT_CUSTOMIZED_BY_EXPERTS_PLAN: "SELECT_CUSTOMIZED_BY_EXPERTS_PLAN",
            SELECT_MARKETING_PLAN: "SELECT_MARKETING_PLAN",
            SELECT_SITE_PLAN: "SELECT_SITE_PLAN",
          },
        },

        PICK_TEMPLATE: {
          entry: "pickTemplate",
          on: {
            SELECT_CUSTOMIZED_BY_EXPERTS_PLAN: "SELECT_CUSTOMIZED_BY_EXPERTS_PLAN",
            SELECT_MARKETING_PLAN: "SELECT_MARKETING_PLAN",
            SELECT_SITE_PLAN: "SELECT_SITE_PLAN",
            PICK_TEMPLATE: "PICK_TEMPLATE",
          },
        },

        SELECT_CUSTOMIZED_BY_EXPERTS_PLAN: {
          entry: "addProduct",
          on: {
            PICK_TEMPLATE: "PICK_TEMPLATE",
            SELECT_MARKETING_PLAN: "SELECT_MARKETING_PLAN",
            SELECT_CUSTOMIZED_BY_EXPERTS_PLAN: "SELECT_CUSTOMIZED_BY_EXPERTS_PLAN",
            SELECT_SITE_PLAN: "SELECT_SITE_PLAN",
            UNSELECT_PLAN: "UNSELECT_PLAN",
          },
        },

        SELECT_SITE_PLAN: {
          entry: "addProduct",
          on: {
            SELECT_CUSTOMIZED_BY_EXPERTS_PLAN: "SELECT_CUSTOMIZED_BY_EXPERTS_PLAN",
            PICK_TEMPLATE: "PICK_TEMPLATE",
            SELECT_SITE_PLAN: "SELECT_SITE_PLAN",
            SELECT_MARKETING_PLAN: "SELECT_MARKETING_PLAN",
            UNSELECT_PLAN: "UNSELECT_PLAN",
          },
        },

        SELECT_MARKETING_PLAN: {
          entry: "addProduct",
          on: {
            SELECT_CUSTOMIZED_BY_EXPERTS_PLAN: "SELECT_CUSTOMIZED_BY_EXPERTS_PLAN",
            SELECT_SITE_PLAN: "SELECT_SITE_PLAN",
            PICK_TEMPLATE: "PICK_TEMPLATE",
            SELECT_MARKETING_PLAN: "SELECT_MARKETING_PLAN",
            UNSELECT_PLAN: "UNSELECT_PLAN",
          },
        },

        UNSELECT_PLAN: {
          entry: "removeProduct",
          on: {
            SELECT_CUSTOMIZED_BY_EXPERTS_PLAN: "SELECT_CUSTOMIZED_BY_EXPERTS_PLAN",
            SELECT_SITE_PLAN: "SELECT_SITE_PLAN",
            SELECT_MARKETING_PLAN: "SELECT_MARKETING_PLAN",
            PICK_TEMPLATE: "PICK_TEMPLATE",
            UNSELECT_PLAN: "UNSELECT_PLAN",
          },
        },
      },
    },
    {
      actions: {
        pickTemplate: (_, event) => {
          service.setTemplate(event.payload);
        },
        addProduct: (_, event) => {
          service.addProduct(event.payload);
        },
        removeProduct: (_, event) => {
          service.removeProduct(event.payload);
        },
      },
    }
  );
