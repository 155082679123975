import { Check } from "../../../../../assets/svg/check";
import { LikeMessage } from "../../../../../assets/svg/like_message";
import { Shield } from "../../../../../assets/svg/shield";
import { Personal } from "../../../../../assets/svg/personal";
import { Free } from "../../../../../assets/svg/free";
import { Business } from "../../../../../assets/svg/business";
import { Product } from "../../../../services/Products/Products";

const cardItemDefinitions1 = [
  {
    icon: <Check />,
    textBold: "Clickjet.site domain",
    textRegular: "",
    tooltipText: "This is a tool tip text that will help user understand the function",
  },
  {
    icon: <Check />,
    textBold: "Clickjet banner",
    textRegular: "",
    tooltipText: "This is a tool tip text that will help user understand the function",
  },
  {
    icon: <Check />,
    textBold: "2",
    textRegular: "website pages",
    tooltipText: "This is a tool tip text that will help user understand the function",
  },
  {
    icon: <Check />,
    textBold: "100",
    textRegular: "form submissions (lifetime)",
    tooltipText: "This is a tool tip text that will help user understand the function",
  },
  {
    icon: <Check />,
    textBold: "1,000",
    textRegular: "visitors/mo",
    tooltipText: "This is a tool tip text that will help user understand the function",
  },
];

const cardItemDefinitions2 = [
  {
    icon: <Check />,
    textBold: "Custom domain",
    textRegular: "",
    tooltipText: "This is a tool tip text that will help user understand the function",
  },
  {
    icon: <Check />,
    textBold: "50",
    textRegular: "website pages",
    tooltipText: "This is a tool tip text that will help user understand the function",
  },
  {
    icon: <Check />,
    textBold: "500",
    textRegular: "form submissions (monthly)",
    tooltipText: "This is a tool tip text that will help user understand the function",
  },
  {
    icon: <Check />,
    textBold: "10,000",
    textRegular: "visitors (monthly)",
    tooltipText: "This is a tool tip text that will help user understand the function",
  },
  {
    icon: <LikeMessage />,
    textBold: "Premium support",
    textRegular: "",
    tooltipText: "This is a tool tip text that will help user understand the function",
  },
  {
    icon: <Shield />,
    textBold: "14-day",
    textRegular: "money back guarantee",
    tooltipText: "This is a tool tip text that will help user understand the function",
  },
];

const discount2 = {
  oldPrice: "$7.99!",
  highlitedText: "Save 50%",
  description: `Billed annually. You pay $47,88 today
Renews at regular rate $95,88/yr`,
};

const cardItemDefinitions3 = [
  {
    icon: <Check />,
    textBold: "Custom domain",
    textRegular: "",
    tooltipText: "This is a tool tip text that will help user understand the function",
  },
  {
    icon: <Check />,
    textBold: "100",
    textRegular: "website pages",
    tooltipText: "This is a tool tip text that will help user understand the function",
  },
  {
    icon: <Check />,
    textBold: "Unlimited",
    textRegular: "form submissions",
    tooltipText: "This is a tool tip text that will help user understand the function",
  },
  {
    icon: <Check />,
    textBold: "100,000",
    textRegular: "visitors (monthly)",
    tooltipText: "This is a tool tip text that will help user understand the function",
  },
  {
    icon: <LikeMessage />,
    textBold: "Premium support",
    textRegular: "",
    tooltipText: "This is a tool tip text that will help user understand the function",
  },
  {
    icon: <Shield />,
    textBold: "14-day",
    textRegular: "money back guarantee",
    tooltipText: "This is a tool tip text that will help user understand the function",
  },
];

const discount3 = {
  oldPrice: "$11.99!",
  highlitedText: "Save 75%",
  description: `Billed annually. You pay $107,88 today
Renews at regular rate $143,88/yr`,
};

const sitePlanProductsData: Product[] = [
  {
    price: 0,
    priceId: import.meta.env.VITE_SITE_PLAN_FREE_ID,
    type: "oneOffProduct",
    productType: "sitePlan",
    meta: {
      name: "Site plan - Free",
      billingPeriodDescription: "Forever",
      tooltipItems: cardItemDefinitions1,
    },
  },
  {
    price: 7.99,
    priceId: import.meta.env.VITE_SITE_PLAN_PERSONAL_ID,
    type: "subscription",
    productType: "sitePlan",
    meta: {
      name: "Site plan - Personal",
      billingPeriodDescription: "Per month",
      billingPeriod: "month",
      tooltipItems: cardItemDefinitions2,
    },
  },
  {
    price: 11.99,
    priceId: import.meta.env.VITE_SITE_PLAN_BUSINESS_ID,
    type: "subscription",
    productType: "sitePlan",
    meta: {
      name: "Site plan - Business",
      billingPeriodDescription: "Per month",
      billingPeriod: "month",
      tooltipItems: cardItemDefinitions3,
    },
  },
];

export const cardData: Card[] = [
  {
    itemsDefinitions: cardItemDefinitions1,
    header: "Free",
    subheader: "Simple, static sites",
    priceDescription: "/forever",
    isFirst: true,
    icon: <Free />,
    ...sitePlanProductsData[0],
  },
  {
    itemsDefinitions: cardItemDefinitions2,
    header: "Personal",
    subheader: "Strengthen online presence",
    priceDescription: "/per month",
    discount: discount2,
    isBestSeller: true,
    icon: <Personal />,
    ...sitePlanProductsData[1],
  },
  {
    itemsDefinitions: cardItemDefinitions3,
    header: "Business",
    subheader: "Companies ready to scale their online presence",
    priceDescription: "/per month",
    isLast: true,
    withoutBorder: true,
    discount: discount3,
    icon: <Business />,
    ...sitePlanProductsData[2],
  },
];

type Card = {
  itemsDefinitions: ItemDefinition[];
  header: string;
  subheader: string;
  priceDescription: string;
  isFirst?: boolean;
  isLast?: boolean;
  discount?: Discount;
  isBestSeller?: boolean;
  withoutBorder?: boolean;
  icon: JSX.Element;
} & Product;

type ItemDefinition = {
  icon: JSX.Element;
  textBold: string;
  textRegular: string;
  tooltipText: string;
};

type Discount = {
  oldPrice: string;
  highlitedText: string;
  description: string;
};
