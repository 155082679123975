/**
 * Represents the properties that can be used to configure a YouTube player.
 *
 * @typedef {("controls" | "related" | "modest" | "loop" | "autoplay")} YoutubePlayerProps
 */
export type YoutubePlayerProps = "controls" | "related" | "modest" | "loop" | "autoplay";

/**
 * Creates a YouTube query URL with specified parameters.
 *
 * @param url - The base URL of the YouTube video.
 * @param types - An array of YoutubePlayerProps that specify the desired player options.
 * @param id - An optional video ID to be included in the URL.
 * @returns The modified YouTube URL with the specified query parameters.
 */
export const createYoutubeQueryUrl = (url: string, types: YoutubePlayerProps[], id?: string): string => {
  let baseUrl = url;

  // If id is provided and not included in the URL, add it
  if (id && !baseUrl.includes(id)) {
    baseUrl = baseUrl.replace("/embed/", `/embed/${id}`);
  }

  const params = new URLSearchParams();

  if (types.includes("controls")) {
    params.append("controls", "0");
    params.append("showinfo", "0");
  }

  if (types.includes("related")) {
    params.append("rel", "0");
  }

  if (types.includes("modest")) {
    params.append("modestbranding", "1");
  }

  if (types.includes("loop")) {
    params.append("loop", "1");
    console.log("id", id);
    params.append("playlist", id || "asdasd");
  }

  if (types.includes("autoplay")) {
    params.append("autoplay", "1");
    params.append("mute", "1");
  }

  return `${baseUrl}?${params.toString()}`;
};
