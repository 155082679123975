import { useProducts } from "../../../hooks/useProducts/useProducts";
import { routerInstance } from "../../../machines/router/router.instance";

import { Cart } from "../../molecules/Cart";
import { CreateAccount } from "../../molecules/CreateAccount";
import { OnboardingLayout } from "../../molecules/OnboardingLayout";

import type { TWrapUp } from "./WrapUp.types";

import "./WrapUp.css";

export const WrapUp: TWrapUp = () => {
  const products = useProducts();
  const cartItemList = products.products.map((product) => ({
    heading: product.meta.name,
    subHeading: product.meta.billingPeriodDescription,
    price: product.price,
    billingPeriod: product.meta.billingPeriod,
  }));

  return (
    <>
      <OnboardingLayout
        onBack={() => routerInstance.send("SELECT_MARKETING_PLAN")}
        seoTitle="Wrap up - Codejet"
        breadcrumbStage={4}
        header="Wrap up"
        isTotalSectionVisible={false}
        isHeaderVisible={false}
      >
        <main className="wrap-up__main">
          <CreateAccount />
          <div>
            <Cart totalAmount="0" isPaySectionVisible={true} isPayButtonDisabled={true} cartItemList={cartItemList} />
          </div>
        </main>
      </OnboardingLayout>
    </>
  );
};
