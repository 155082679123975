import { useEffect } from "react";
import { toast } from "react-toastify";

import { Figma } from "../../../services/Figma";

import { Seo } from "../../atoms/Seo";
import { ButtonNew } from "../../atoms/ButtonNew";
import { WeirdIconWrapper } from "../../atoms/WeirdIconWrapper";

import { Logotype } from "../../../../assets/svg/logotype";
import { CodejetIconNew } from "../../../../assets/svg/codejet_icon_new";
import { FigmaLogo } from "../../../../assets/svg/figma_logo";
import { FacebookLogo } from "../../../../assets/svg/facebook_logo";
import { GoogleLogo } from "../../../../assets/svg/google_logo";

import type { TLogin } from "./Login.types";

import "./Login.css";

let counter = 0;

export const Login: TLogin = ({ toastMsg }) => {
  useEffect(() => {
    if (toastMsg !== undefined && counter === 0) toast.info(toastMsg);
    counter++;
  }, []);
  return (
    <>
      <Seo title="Get started - Codejet" />
      <div className="login">
        <div className="login__wrapper">
          <Logotype />
          <div className="login__content">
            <WeirdIconWrapper>
              <CodejetIconNew />
            </WeirdIconWrapper>
            <div className="login__text-block">
              <h1 className="login__header">Login to Codejet</h1>
              <p className="login__description">
                Create custom websites directly from
                <br />
                your Figma designs
              </p>
            </div>
            <ButtonNew
              className="login__content-button"
              iconStart={<GoogleLogo />}
              href={"api/auth/google/login"}
              target="_self"
            >
              Login with Google
            </ButtonNew>
            <ButtonNew
              className="login__content-button"
              iconStart={<FacebookLogo />}
              href={"api/auth/facebook/login"}
              target="_self"
            >
              Login with Facebook
            </ButtonNew>
            <ButtonNew
              className="login__content-button"
              iconStart={<FigmaLogo />}
              href={Figma.getCallbackUrl()}
              target="_self"
            >
              Login with Figma
            </ButtonNew>
          </div>
          <div className="login__footer">
            <p className="login__footer-text">{"Don't have an account yet?"}</p>
            <ButtonNew type="text" className="login__footer-link" orange href="https://www.figma.com/signup?locale=en">
              Create Figma account
            </ButtonNew>
          </div>
        </div>
      </div>
    </>
  );
};
