import { templatesInstance } from "../../../../machines/templates/templates.instance";
import { dashboardInstance } from "../../../../machines/dashboard/dashboard.instance";

import { NoContent } from "./NoContent";
import { TemplateCard } from "../../atoms/TemplateCard";

import { type Project } from "../../../../types/types";

export const RenderContent = ({
  isSkeleton,
  isNoContent,
  templates,
}: {
  isSkeleton: boolean;
  isNoContent: boolean;
  templates: Project[];
}) => {
  const handleDuplicateTemplate = async (projectId: string) => {
    await templatesInstance.getSnapshot().context.service.duplicateTemplate(projectId);
    dashboardInstance.send("PROJECTS");
  };
  const handleSelectTemplate = (projectId: string) => {
    templatesInstance.send("SELECT_TEMPLATE", { projectId });
  };

  if (isSkeleton) {
    return (
      <>
        {Array.from({ length: 3 }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <TemplateCard key={index} skeleton style={{ animationDelay: `calc(${index} * 250ms)` }} />
        ))}
      </>
    );
  } else if (isNoContent) {
    return <NoContent />;
  } else {
    return (
      <>
        {templates.map((project, idx) => {
          return (
            <TemplateCard
              key={project.projectId}
              name={project.name}
              description={project.description}
              thumbnail={project.thumbnail}
              onClick={() => handleSelectTemplate(project.projectId)}
              onDuplicate={async () => handleDuplicateTemplate(project.projectId)}
              style={{ animationDelay: `calc(${idx} * 250ms)`, zIndex: `${996 - idx}` }}
            />
          );
        })}
      </>
    );
  }
};
