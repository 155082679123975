import "grapesjs/dist/css/grapes.min.css";
import { useEffect, useState } from "react";
import { editorTemplatesInstance, editorService } from "./modules/editor/index.ts";

import { Seo } from "../../atoms/Seo";
import { TopBarTemplates } from "../../AlphaO/molecules/TobBarTemplates/TopBarTemplates.tsx";
import { LoaderBackdrop } from "../../molecules/LoaderBackdrop";
import { LeftSidebar } from "../../AlphaO/molecules/LeftSidebar/LeftSidebar.tsx";
import { UpdateNotification } from "../../AlphaO/atoms/UpdateNotification/UpdateNotification.tsx";

import { EyeSolid } from "../../../assets/svg/EyeSolid.tsx";

import { TAlpha_O_templates } from "./Alpha_O_templates.types.ts";

import "../Alpha_O/style.css";

export const Alpha_O_templates: TAlpha_O_templates = ({ isAuthenticated }) => {
  const [state, updateState] = useState({});
  const [isPreview, setIsPreview] = useState(false);
  const isLoaded = editorService.editorIsLoaded;

  useEffect(() => {
    editorTemplatesInstance.start();
    editorService.subscribe((st) => updateState(st));

    return () => {
      editorTemplatesInstance.stop();
      editorService.clearEditor();
      editorService.unsubscribe(updateState);
    };
  }, []);

  const togglePreview = () => {
    editorTemplatesInstance.send("TOGGLE_PREVIEW");
    setIsPreview((prev) => !prev);
  };

  return (
    <>
      <Seo title="Visual Editor - Codejet" />
      <div className={`alpha-o${isPreview ? " preview" : ""}`}>
        {!isLoaded && (
          <div className="alpha-o__veil">
            <LoaderBackdrop />
          </div>
        )}
        <TopBarTemplates togglePreview={togglePreview} isAuthenticated={isAuthenticated} />
        <div className={`main-section preview`}>
          <LeftSidebar />
          <div className="main-section__editor">
            <div id={"gjs"}></div>
            <UpdateNotification />
          </div>
          <button className="switch-off-preview" onClick={togglePreview}>
            <EyeSolid />
          </button>
        </div>
      </div>
    </>
  );
};
