import { routerInstance } from "../../../machines/router/router.instance";
import { Logotype } from "../../../../assets/svg/logotype";

import type { TUnauthorizedNavbar } from "./UnauthorizedNavbar.types";

import "./UnauthorizedNavbar.css";

export const UnauthorizedNavbar: TUnauthorizedNavbar = () => {
  return (
    <div className="unauthorized-navbar">
      <div className="unauthorized-navbar__container">
        <div className="unauthorized-navbar__logo">
          <Logotype />
        </div>
        <div className="unauthorized-navbar__login">
          Already have an account?
          <button
            onClick={() => {
              routerInstance.send("LOGIN");
            }}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};
