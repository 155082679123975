import { useEffect, useRef, useState } from "react";

import useOutsideClick from "../../../../../hooks/useOutsideClick/useOutsideClick";

import type { SelectOption, TSelect } from "./Select.types";

import "./Select.css";

export const Select: TSelect = ({ label, options, selectedOption, onChange }) => {
  const [isSelectInputActive, setIsSelectInputActive] = useState<boolean>(false);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState<number>(0);
  const ref = useOutsideClick<HTMLDivElement>(() => setIsSelectInputActive(false));
  const optionRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    if (isSelectInputActive && optionRefs.current[focusedOptionIndex]) {
      optionRefs.current[focusedOptionIndex]?.focus();
    }
  }, [focusedOptionIndex, isSelectInputActive]);

  const onChangeHandler = (option: SelectOption) => {
    setIsSelectInputActive(false);
    onChange(option);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        if (!isSelectInputActive) {
          setIsSelectInputActive(true);
        } else {
          setFocusedOptionIndex((prevIndex) => (prevIndex + 1) % options.length);
        }
        break;
      case "ArrowUp":
        e.preventDefault();
        if (isSelectInputActive) {
          setFocusedOptionIndex((prevIndex) => (prevIndex - 1 + options.length) % options.length);
        }
        break;
      case "Enter":
        e.preventDefault();
        if (!isSelectInputActive) {
          setIsSelectInputActive(true);
        } else {
          onChangeHandler(options[focusedOptionIndex]);
        }
        break;
      case "Escape":
        e.preventDefault();
        setIsSelectInputActive(false);
        break;
      default:
        break;
    }
  };

  const handleOptionKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, item: SelectOption) => {
    if (e.key === "Enter" && !isSelectInputActive) {
      setIsSelectInputActive(true);

      return;
    }

    if (e.key === "Enter") {
      onChangeHandler(item);
    }
  };

  return (
    <div className="modal-select">
      <div className="modal-select__label">{label}</div>
      <div
        className="modal-select__container"
        ref={ref}
        role="combobox"
        aria-haspopup="listbox"
        aria-expanded={isSelectInputActive}
        aria-labelledby="select-label"
      >
        <div
          className={"modal-select__selected-option" + (!selectedOption ? " placeholder" : "")}
          onClick={() => setIsSelectInputActive(!isSelectInputActive)}
          role="button"
          aria-haspopup="listbox"
          aria-expanded={isSelectInputActive}
          tabIndex={0}
          onKeyDown={(e) => handleKeyDown(e)}
        >
          {(selectedOption && selectedOption.label) || options[0].label}
        </div>
        <div
          className={"modal-select__options" + (isSelectInputActive ? " active" : "")}
          role="listbox"
          aria-labelledby="selected-option"
          onKeyDown={(e) => handleKeyDown(e)}
        >
          {options.map((item, index) => {
            return (
              <div
                className="modal-select__select-option"
                onClick={() => onChangeHandler(item)}
                role="option"
                key={item.value}
                aria-selected={selectedOption ? selectedOption.value === item.value : false}
                ref={(el) => (optionRefs.current[index] = el)}
                tabIndex={0}
                onKeyDown={(e) => handleOptionKeyDown(e, item)}
              >
                {item.label}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
