import { useEffect, useState } from "react";

import { FormModalService as FormModalService } from "./FormModal.service";
import useOutsideClick from "../../../hooks/useOutsideClick/useOutsideClick";

import { Header } from "../../atoms/Header";
import { Form } from "./atoms/Form";
import { ButtonNew } from "../../atoms/ButtonNew";

import CeoIcon from "../../../../assets/png/ceo-avatar.png";

import { TFormModal } from "./FormModal.types";

import "./FormModal.css";

const formModalService = FormModalService.getInstance();

export const FormModal: TFormModal = () => {
  const [_, setState] = useState(false);
  const isOpen = formModalService.state;
  const closeModal = () => formModalService.closeModal();
  const ref = useOutsideClick<HTMLDivElement>(closeModal);

  useEffect(() => {
    formModalService.subscribe(setState);
    return () => formModalService.unsubscribe(setState);
  }, []);

  return (
    <div className="form-modal__wrapper">
      <div className={"form-modal__backdrop" + (isOpen ? " form-modal__backdrop--visible" : "")}>
        <div className={"form-modal" + (isOpen ? " form-modal--visible" : "")} ref={ref}>
          <Header
            iconSrc={CeoIcon}
            headingText="Let our experts create website just for you in under 24 hours!"
            subheadingText="Let us know what you need, and one of our experts will reach out to you after your order is placed."
          />
          <Form />
          <div className="form-modal__button-group">
            <ButtonNew className="form-modal__button" onClick={closeModal}>
              Cancel
            </ButtonNew>
            <ButtonNew className="form-modal__button" onClick={closeModal} orange>
              Continue
            </ButtonNew>
          </div>
        </div>
      </div>
    </div>
  );
};
