import { ButtonNew } from "../../atoms/ButtonNew";

import { CartItem } from "./atoms/CartItem";

import { Lock } from "../../../../assets/svg/lock";
import { Shield } from "../../../../assets/svg/shield";

import type { TCart } from "./Cart.types";

import "./Cart.css";

export const Cart: TCart = ({ taxAmount, totalAmount, cartItemList, isPaySectionVisible, isPayButtonDisabled }) => {
  return (
    <div className="codejet__cart">
      <div className={"codejet__cart-container" + (!isPaySectionVisible ? " without__pay-section" : "")}>
        <h2 className="codejet__typography">Cart</h2>
        {cartItemList.map(({ heading, subHeading, price, scoredOutPrice, isSubHeadingGreen, billingPeriod }, index) => (
          <CartItem
            key={index}
            heading={heading}
            subHeading={subHeading}
            price={price}
            scoredOutPrice={scoredOutPrice}
            isSubHeadingGreen={isSubHeadingGreen}
            billingPeriod={billingPeriod}
            tooltip={[
              {
                boldText: "Clickjet.site domain",
              },
              {
                boldText: "Clickjet banner",
              },
              {
                boldText: "2",
                regularText: "website pages",
              },
              {
                boldText: "100",
                regularText: "form submissions (lifetime)",
              },
              {
                boldText: "1,000",
                regularText: "visitors/mo",
              },
            ]}
          />
        ))}
        <div className="codejet__cart-divider"></div>
        {taxAmount !== undefined && (
          <div className="codejet__tax">
            <div className="codejet__typography codejet__tax-name">Tax</div>
            <div className="codejet__typography codejet__tax-price">${taxAmount}</div>
          </div>
        )}
        <div className="codejet__total">
          <div className="codejet__typography codejet__total-name">Total</div>
          <div className="codejet__typography codejet__total-price">${totalAmount}</div>
        </div>
        {isPaySectionVisible && (
          <>
            <ButtonNew className="codejet__cart-button" orange disabled={isPayButtonDisabled}>
              Pay now
            </ButtonNew>
            <div className="codejet__typography codejet__bottom-description">
              By purchasing, you acknowledge that your subscription will be renewed automatically, unless you switch to
              manual renewal.
            </div>
          </>
        )}
      </div>
      {isPaySectionVisible && (
        <div className="codejet__typography codejet__bottom-banner">
          <div className="codejet__bottom-banner__item">
            <Lock className="codejet__bottom-banner__icon" />
            Your payment is 100% secure
          </div>
          <div className="codejet__bottom-banner__item">
            <Shield className="codejet__bottom-banner__icon" />
            14-day money back guarantee
          </div>
        </div>
      )}
    </div>
  );
};
