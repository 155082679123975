import { Trait } from "grapesjs";

import { Video, Checkbox, Href, Range, Select, SelectOptionsTrait, Text } from "./index";

export const TypeManager = ({ trait }: { trait: Trait }) => {
  if (trait.getType() === "text" && trait.getLabel() === "href") {
    return <Href trait={trait} />;
  }
  //Redirect is type of text but should be handled differently
  if (trait.getType() === "text" && trait.getLabel() === "data-redirect") {
    return <Href trait={trait} />;
  }

  if (trait.getName() === "videoProvider" && trait.getType() === "select") {
    return <Video trait={trait} />;
  }

  if (trait.getType() === "text") {
    return <Text trait={trait} />;
  }

  if (trait.getType() === "select") {
    return <Select trait={trait} />;
  }

  if (trait.getType() === "checkbox") {
    return <Checkbox trait={trait} />;
  }

  if (trait.getType() === "select-options") {
    return <SelectOptionsTrait trait={trait} />;
  }

  if (trait.getType() === "range") {
    return <Range trait={trait} />;
  }

  return "Type not supported" + trait.getType();
};
