import { Product } from "../../../../services/Products/Products";

const cardItemDefinitions1 = [
  {
    textRegular: "Guaranteed over 1,000 website visitors",
  },
  {
    textRegular: "~5-10 new potential customers / month",
  },
];

const cardItemDefinitions2 = [
  {
    textRegular: "Guaranteed over 2,000 website visitors",
  },
  {
    textRegular: "~10-20 new potential customers / month",
  },
];

const cardItemDefinitions3 = [
  {
    textRegular: "Guaranteed over 5,000 website visitors",
  },
  {
    textRegular: "~30-50 new potential customers / month",
  },
];

const cardItemDefinitions4 = [
  {
    textRegular: "Guaranteed over 10,000 website visitors",
  },
  {
    textRegular: "~60-100 new potential customers / month",
  },
];

export const marketingPlanProductsData: Product[] = [
  {
    price: 39,
    priceId: import.meta.env.VITE_MARKETING_PLAN_STARTER_ID,
    type: "oneOffProduct",
    productType: "marketingPlan",
    meta: {
      name: "Marketing plan - Starter",
      billingPeriodDescription: "Forever",
      tooltipItems: cardItemDefinitions1,
    },
  },
  {
    price: 89,
    priceId: import.meta.env.VITE_MARKETING_PLAN_BOOST_ID,
    type: "subscription",
    productType: "marketingPlan",
    meta: {
      name: "Marketing plan - Boost",
      billingPeriodDescription: "Per month",
      tooltipItems: cardItemDefinitions2,
      billingPeriod: "month",
    },
  },
  {
    price: 149,
    priceId: import.meta.env.VITE_MARKETING_PLAN_POWER_ID,
    type: "subscription",
    productType: "marketingPlan",
    meta: {
      name: "Marketing plan - Power",
      billingPeriodDescription: "Per month",
      tooltipItems: cardItemDefinitions3,
      billingPeriod: "month",
    },
  },
  {
    price: 299,
    priceId: import.meta.env.VITE_MARKETING_PLAN_MASTER_ID,
    type: "subscription",
    productType: "marketingPlan",
    meta: {
      name: "Marketing plan - Master",
      billingPeriodDescription: "Per month",
      tooltipItems: cardItemDefinitions4,
      billingPeriod: "month",
    },
  },
];

export const cardData: Card[] = [
  {
    itemsDefinitions: cardItemDefinitions1,
    header: "Starter",
    subheader: "Ignite business journey",
    highlitedText: "5 potential clients each month!",
    priceDescription: "/forever",
    isFirst: true,
    ...marketingPlanProductsData[0],
  },
  {
    itemsDefinitions: cardItemDefinitions2,
    header: "Boost",
    subheader: "Strengthen online presence",
    highlitedText: "10 potential clients each month!",
    priceDescription: "/per month",
    ...marketingPlanProductsData[1],
  },
  {
    itemsDefinitions: cardItemDefinitions3,
    header: "Power",
    subheader: "Supercharge business growth",
    highlitedText: "30 potential clients each month!",
    priceDescription: "/per month",
    ...marketingPlanProductsData[2],
  },
  {
    itemsDefinitions: cardItemDefinitions4,
    header: "Master",
    subheader: "Take your business to next level",
    highlitedText: "60 potential clients each month!",
    priceDescription: "/per month",
    isLast: true,
    ...marketingPlanProductsData[3],
  },
];

type Card = {
  itemsDefinitions: ItemDefinition[];
  header: string;
  subheader: string;
  priceDescription: string;
  highlitedText?: string;
  isFirst?: boolean;
  isLast?: boolean;
} & Product;

type ItemDefinition = {
  icon?: JSX.Element;
  textBold?: string;
  textRegular?: string;
  tooltipText?: string;
};
