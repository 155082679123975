import { useState } from "react";

import { Select } from "../Select";

import type { SelectOption } from "../Select/Select.types";
import type { TForm } from "./Form.types";

import "./Form.css";

export const Form: TForm = () => {
  // Mock options, need to be replaced with real data.
  const options = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
  ];
  const [componySelectedOption, setCompanySelectedOption] = useState<SelectOption | null>(null);
  const [websiteSelectedOption, setWebsiteSelectedOption] = useState<SelectOption | null>(null);

  const selectCompanyOption = (selectedOption: SelectOption) => {
    setCompanySelectedOption(selectedOption);
  };

  const selectWebsiteOption = (selectedOption: SelectOption) => {
    setWebsiteSelectedOption(selectedOption);
  };

  return (
    <div className="form-container">
      <form>
        <div className="form-group__business-name">
          <label htmlFor="business-name">Business name</label>
          <input
            type="text"
            className="business-name"
            placeholder="Enter your business name"
            aria-required="true"
            aria-describedby="business-name-description"
          />
        </div>
        <Select
          label="Company category"
          options={options}
          onChange={selectCompanyOption}
          selectedOption={componySelectedOption}
        />
        <Select
          label="Website goal"
          options={options}
          onChange={selectWebsiteOption}
          selectedOption={websiteSelectedOption}
          aria-describedby="comments-description"
        />
        <div className="form-group__comments">
          <label htmlFor="additional-comments">Additional comments</label>
          <textarea className="additional-comments" placeholder="Enter comments"></textarea>
        </div>
      </form>
    </div>
  );
};
