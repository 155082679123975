import { useEffect, useState } from "react";

// import { data as fecthedTemplates } from "./mockdata";
import { useTemplates } from "../../../hooks/useTemplates/useTemplates";

import { routerInstance } from "../../../machines/router/router.instance";
import { productsInstance } from "../../../machines/products/products.instance";

import { FormModal } from "../../molecules/FormModal";
import { FormModalService } from "../../molecules/FormModal/FormModal.service";
import { OnboardingLayout } from "../../molecules/OnboardingLayout";
import { UseTemplateModal } from "../../molecules/UseTemplateModal";
import { UseTemplateModalService } from "../../molecules/UseTemplateModal/UseTemplateModal.service";
import { ImportModal as ImportModalService } from "../../Dashboard/molecules/ImportModal/ImportModal.service";
import { ImportModal } from "../../Dashboard/molecules/ImportModal";

// import { ButtonNew } from "../../atoms/ButtonNew";
import { TemplateCard } from "../../Dashboard/atoms/TemplateCard";
import { NewTemplateCard } from "../../atoms/NewTemplateCard";
import { ImportFigmaSection } from "./atoms/ImportFigmaSection";
import { PriceSection } from "./atoms/PriceSection";

import type { TUnauthorizedTemplate } from "./UnauthorizedTemplates.types";

import "./UnauthorizedTemplates.css";
import { unauthorizedTemplatesInstance } from "../../../machines/unauthorized-templates/unauthorized-templates.instance";

const categories = [
  "All",
  "Services",
  "Fitness",
  "Creators",
  "Healthcare",
  "Education",
  "Restaurant",
  "Travel",
  "Nonprofits",
];

export const UnauthorizedTemplate: TUnauthorizedTemplate = () => {
  // const [selectedCategory, setSelectedCategory] = useState<string>("All");
  // const [displayedTemplates, setDisplayedTemplates] = useState(fecthedTemplates);
  const useTemplateModalService = UseTemplateModalService.getInstance();
  const formModalService = FormModalService.getInstance();
  const importModalService = ImportModalService.getInstance();
  const [isSkeleton, setIsSkeleton] = useState(true);
  const templates = useTemplates(setIsSkeleton);

  // useEffect(() => {
  //   if (selectedCategory === "All") {
  //     setDisplayedTemplates(fecthedTemplates);
  //   } else {
  //     const selectedTemplates = fecthedTemplates.filter((template) => template.category === selectedCategory);

  //     setDisplayedTemplates(selectedTemplates);
  //   }
  // }, [selectedCategory]);

  const openFormModal = () => {
    formModalService.openModal();
  };

  const openUseTemplateModal = (projectId: string) => {
    productsInstance.send({ type: "PICK_TEMPLATE", payload: { projectId } });
    useTemplateModalService.openModal();
  };

  const openImportModal = () => {
    importModalService.openModal();
  };

  const onPreviewHandler = (projectId: string) => {
    unauthorizedTemplatesInstance.send({ type: "PREVIEW_TEMPLATE", payload: { projectId } });
  };

  const onContinueHandler = () => {
    routerInstance.send("SELECT_PLAN_SITE");
  };

  return (
    <>
      <OnboardingLayout
        seoTitle="My templates - Codejet"
        header="Pick a template"
        subheader="Get started quicker with prebuilt templates"
        breadcrumbStage={1}
        isTotalSectionVisible={false}
        isBackButtonVisible={false}
      >
        <div className="unauthorized-templates__wrapper">
          {/* <div className="unauthorized-templates__categories">
            {categories.map((category: string) => {
              const isSelected = category === selectedCategory ? true : false;
              const isActive = isSelected ? " active" : "";

              return (
                <ButtonNew
                  key={category}
                  className={"unauthorized-templates__category-button" + isActive}
                  onClick={() => setSelectedCategory(category)}
                >
                  {category}
                </ButtonNew>
              );
            })}
          </div> */}
          <div className="unauthorized-templates__templates">
            {isSkeleton
              ? Array.from({ length: 4 }).map((_, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TemplateCard
                    key={index}
                    skeleton
                    style={{ animationDelay: `calc(${index} * 250ms)`, height: "30rem" }}
                  />
                ))
              : templates.map(({ projectId, thumbnail, name }) => (
                  <NewTemplateCard
                    key={projectId}
                    name={name}
                    thumbnail={thumbnail}
                    onUseTemplate={() => openUseTemplateModal(projectId)}
                    onPreview={() => onPreviewHandler(projectId)}
                  />
                ))}
          </div>
          <PriceSection openFormModal={openFormModal} />
          <ImportFigmaSection openImportModal={openImportModal} />
        </div>
      </OnboardingLayout>
      <FormModal />
      <ImportModal />
      <UseTemplateModal
        onContinue={onContinueHandler}
        onSelect={(product) => productsInstance.send({ type: "SELECT_CUSTOMIZED_BY_EXPERTS_PLAN", payload: product })}
      />
    </>
  );
};
