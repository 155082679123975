import { BlockProperties } from "grapesjs";

export const blocksProperties: BlockProperties[] = [
  {
    id: "block-1-col",
    media: `<svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1.16669" y="0.5" width="39" height="39" rx="1.5" fill="#27272A" stroke="#71717A"/></svg>`,
    label: "Frame",
    category: "Layout",
    content: {
      type: "cjRow",
      components: [
        {
          type: "cjColumn",
        },
      ],
    },
    select: true,
  },
  {
    id: "block-2-col-even",
    media: `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="18" height="39" rx="1.5" fill="#27272A" stroke="#71717A"/><rect x="21.5" y="0.5" width="18" height="39" rx="1.5" fill="#27272A" stroke="#71717A"/></svg>
    `,
    label: "2 columns",
    category: "Layout",
    content: {
      type: "cjRow",
      components: [
        {
          type: "cjColumn",
          classes: ["cj-grid-column--2"],
        },
        {
          type: "cjColumn",
          classes: ["cj-grid-column--2"],
        },
      ],
    },
    select: true,
  },
  {
    id: "block-3-col-even",
    media: `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="11" height="39" rx="1.5" fill="#27272A" stroke="#71717A"/><rect x="14.5" y="0.5" width="11" height="39" rx="1.5" fill="#27272A" stroke="#71717A"/><rect x="28.5" y="0.5" width="11" height="39" rx="1.5" fill="#27272A" stroke="#71717A"/></svg>
    `,
    label: "3 columns",
    category: "Layout",
    content: {
      type: "cjRow",
      components: [
        {
          type: "cjColumn",
          classes: ["cj-grid-column--3"],
        },
        {
          type: "cjColumn",
          classes: ["cj-grid-column--3"],
        },
        {
          type: "cjColumn",
          classes: ["cj-grid-column--3"],
        },
      ],
    },
    select: true,
  },
  {
    id: "block-grid",
    media: `<svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1.16669" y="0.5" width="18" height="18" rx="1.5" fill="#27272A" stroke="#71717A"/><rect x="22.1667" y="0.5" width="18" height="18" rx="1.5" fill="#27272A" stroke="#71717A"/><rect x="1.16669" y="21.5" width="18" height="18" rx="1.5" fill="#27272A" stroke="#71717A"/><rect x="22.1667" y="21.5" width="18" height="18" rx="1.5" fill="#27272A" stroke="#71717A"/></svg>`,
    label: "Grid",
    category: "Layout",
    content: {
      type: "cjRow",
      components: [
        {
          type: "cjColumn",
          classes: ["cj-grid-column--2"],
        },
        {
          type: "cjColumn",
          classes: ["cj-grid-column--2"],
        },
        {
          type: "cjColumn",
          classes: ["cj-grid-column--2"],
        },
        {
          type: "cjColumn",
          classes: ["cj-grid-column--2"],
        },
      ],
    },
    select: true,
  },
  {
    id: "block-2-rows",
    media: `<svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1.16675" y="0.5" width="39" height="18" rx="1.5" fill="#27272A" stroke="#71717A"/><rect x="1.16675" y="21.5" width="39" height="18" rx="1.5" fill="#27272A" stroke="#71717A"/></svg>`,
    label: "2 rows",
    category: "Layout",
    content: {
      type: "cjRow",
      components: [
        {
          type: "cjRow",
        },
        {
          type: "cjRow",
        },
      ],
    },
    select: true,
  },
  {
    id: "block-3-rows",
    media: `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="39" height="11" rx="1.5" fill="#27272A" stroke="#71717A"/><rect x="0.5" y="14.5" width="39" height="11" rx="1.5" fill="#27272A" stroke="#71717A"/><rect x="0.5" y="28.5" width="39" height="11" rx="1.5" fill="#27272A" stroke="#71717A"/></svg>`,
    label: "3 rows",
    category: "Layout",
    content: {
      type: "cjRow",
      components: [
        {
          type: "cjRow",
        },
        {
          type: "cjRow",
        },
        {
          type: "cjRow",
        },
      ],
    },
    select: true,
  },
  // {
  //   id: "section",
  //   media: `<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="12" y1="11.5" x2="68" y2="11.5" stroke="#71717A"/><rect x="12.5" y="20.5" width="55" height="39" rx="3.5" fill="#27272A" stroke="#71717A"/><line x1="12" y1="67.5" x2="68" y2="67.5" stroke="#71717A"/></svg>`,
  //   label: "Section",
  //   category: "Layout",
  //   content: {
  //     type: "cjSection",
  //     components: [
  //       {
  //         type: "cjContainer",
  //       },
  //     ],
  //   },
  //   select: true,
  // },
  {
    id: "separator",
    media: `<svg width="41" height="29" viewBox="0 0 41 29" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.381 23.8571L20.6667 28.1428L24.9524 23.8571" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/><rect x="1.16669" y="12.2143" width="39" height="4.71429" rx="2.35714" fill="#27272A" stroke="#71717A"/><path d="M16.381 5.28571L20.6667 0.999992L24.9524 5.28571" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
    label: "Separator",
    category: "Layout",
    content: {
      type: "Separator",
    },
    select: true,
  },
  {
    id: "image",
    media: `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_23265_5084)"><path class="dark-fill" d="M22.3333 1H3.66667C2.19391 1 1 2.19391 1 3.66667V22.3333C1 23.8061 2.19391 25 3.66667 25H22.3333C23.8061 25 25 23.8061 25 22.3333V3.66667C25 2.19391 23.8061 1 22.3333 1Z" fill="#27272A" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.33203 10.3335C9.4366 10.3335 10.332 9.43807 10.332 8.3335C10.332 7.22893 9.4366 6.3335 8.33203 6.3335C7.22746 6.3335 6.33203 7.22893 6.33203 8.3335C6.33203 9.43807 7.22746 10.3335 8.33203 10.3335Z" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/><path d="M24.9993 17L18.3327 10.3333L3.66602 25" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_23265_5084"><rect width="26" height="26" fill="white"/></clipPath></defs></svg>`,
    label: "Image",
    category: "Layout",
    content: {
      type: "image",
    },
    select: true,
  },
  {
    id: "image-block",
    media: `<svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.833252" y="0.5" width="39" height="39" rx="3.5" fill="#27272A"/><rect x="0.833252" y="0.5" width="39" height="39" rx="3.5" stroke="#71717A"/><path class="light-stroke" d="M13.3333 29H27.3333C28.4378 29 29.3333 28.1046 29.3333 27V13C29.3333 11.8954 28.4378 11 27.3333 11H13.3333C12.2287 11 11.3333 11.8954 11.3333 13V27C11.3333 28.1046 12.2287 29 13.3333 29ZM13.3333 29L24.3333 18L29.3333 23M18.3333 16.5C18.3333 17.3284 17.6617 18 16.8333 18C16.0048 18 15.3333 17.3284 15.3333 16.5C15.3333 15.6716 16.0048 15 16.8333 15C17.6617 15 18.3333 15.6716 18.3333 16.5Z" stroke="#D4D4D8" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
    label: "Image block",
    category: "Layout",
    content: {
      type: "cjRow",
      components: [{ type: "image" }],
    },
    select: true,
  },
  {
    id: "CODEJET-video",
    media: `<svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_23268_5589)"><path class="dark-fill" d="M30.6654 3.3335L21.332 10.0002L30.6654 16.6668V3.3335Z" fill="#27272A" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/><path class="dark-fill" d="M18.6654 0.666504H3.9987C2.52594 0.666504 1.33203 1.86041 1.33203 3.33317V16.6665C1.33203 18.1393 2.52594 19.3332 3.9987 19.3332H18.6654C20.1381 19.3332 21.332 18.1393 21.332 16.6665V3.33317C21.332 1.86041 20.1381 0.666504 18.6654 0.666504Z" fill="#27272A" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_23268_5589"><rect width="32" height="20" fill="white"/></clipPath></defs></svg>`,
    label: "Video",
    category: "Layout",
    content: {
      type: "codejetVideo",
      label: "Video",
    },
    select: true,
  },
  {
    id: "map",
    media: `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="dark-fill" d="M1.33398 8.00002V29.3334L10.6673 24L21.334 29.3334L30.6673 24V2.66669L21.334 8.00002L10.6673 2.66669L1.33398 8.00002Z" fill="#27272A" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/><path class="dark-fill"  d="M10.668 2.6665V23.9998" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/><path d="M21.334 8V29.3333" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
    label: "Map",
    category: "Layout",
    content: {
      type: "map",
    },
    select: true,
  },
  // {
  //   id: "icon",
  //   media: `<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M39.9993 26.6665L44.1193 35.0132L53.3327 36.3598L46.666 42.8532L48.2393 52.0265L39.9993 47.6932L31.7593 52.0265L33.3327 42.8532L26.666 36.3598L35.8793 35.0132L39.9993 26.6665Z" fill="#27272A" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
  //   label: "Icon",
  //   category: "Layout",
  //   content: {
  //     type: "svg",
  //     attributes: { viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", width: "24px", height: "24px" },
  //     content: `<path
  //       d="M15.2496 8.75116V14.0003L11.7497 10.4997L11.7515 7.00187H8.24986V10.5004L4.75 14.0003V8.75116L10.0029 3.5L15.2496 8.75116Z"
  //       fill="white"
  //     />
  //     <path d="M11.7497 17.5V14.0004H8.27994V17.5H11.7497Z" fill="#D9D9D9" />
  //     <path
  //       d="M15.2496 8.75116V14.0003L11.7497 10.4997L11.7515 7.00187H8.24986V10.5004L4.75 14.0003V8.75116L10.0029 3.5L15.2496 8.75116Z"
  //       fill="#FF4900"
  //     />`,
  //   },
  //   select: true,
  // },
  {
    id: "icon",
    media: `<svg width="29" height="27" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="dark-fill" d="M14.3333 1L18.4533 9.34667L27.6667 10.6933L21 17.1867L22.5733 26.36L14.3333 22.0267L6.09333 26.36L7.66667 17.1867L1 10.6933L10.2133 9.34667L14.3333 1Z" fill="#27272A" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
    label: "Icon",
    category: "Layout",
    // content: {
    //   type: "text",
    //   classes: ["material-symbols-outlined"],
    //   tagName: "span",
    //   content: "search",
    // },
    content: {
      type: "icon",
    },
    select: true,
  },
  {
    id: "heading",
    media: `<svg width="44" height="37" viewBox="0 0 44 37" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_23294_2269)"><path d="M0.833313 20H42.8333M0.833313 28H42.8333M0.833313 36H21.8333" stroke="#71717A" stroke-linecap="round"/><path class="light-fill" d="M1.32056 12V0.363647H2.72965V5.54547H8.93419V0.363647H10.3433V12H8.93419V6.79547H2.72965V12H1.32056Z" fill="#D4D4D8"/></g><defs><clipPath id="clip0_23294_2269"><rect width="43" height="37" fill="white" transform="translate(0.333313)"/></clipPath></defs></svg>
    `,
    label: "Heading",
    category: "Layout",
    content: {
      type: "text",
      tagName: "h2",
      content: "Insert your title here",
    },
    select: true,
  },
  {
    id: "text",
    media: `<svg width="42" height="23" viewBox="0 0 42 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="light-fill" d="M5.48441 22H1.26566L8.94677 0.181818H13.826L21.5178 22H17.299L11.4716 4.65625H11.3012L5.48441 22ZM5.62291 13.4453H17.1286V16.62H5.62291V13.4453ZM24.3916 22V0.181818H28.2481V8.34233H28.4079C28.6068 7.9446 28.8873 7.52202 29.2495 7.07457C29.6117 6.62003 30.1018 6.23295 30.7197 5.91335C31.3376 5.58665 32.1259 5.4233 33.0847 5.4233C34.3489 5.4233 35.4889 5.74645 36.5045 6.39276C37.5272 7.03196 38.3369 7.98011 38.9335 9.23722C39.5371 10.4872 39.839 12.0213 39.839 13.8395C39.839 15.6364 39.5443 17.1634 38.9548 18.4205C38.3653 19.6776 37.5627 20.6364 36.5471 21.2969C35.5315 21.9574 34.3809 22.2876 33.0954 22.2876C32.1579 22.2876 31.3802 22.1314 30.7623 21.8189C30.1444 21.5064 29.6472 21.13 29.2708 20.6896C28.9015 20.2422 28.6139 19.8196 28.4079 19.4219H28.1842V22H24.3916ZM28.1735 13.8182C28.1735 14.8764 28.3227 15.8033 28.621 16.5987C28.9264 17.3942 29.3631 18.0156 29.9313 18.4631C30.5066 18.9034 31.2026 19.1236 32.0194 19.1236C32.8717 19.1236 33.5854 18.8963 34.1607 18.4418C34.736 17.9801 35.1693 17.3516 35.4604 16.5561C35.7587 15.7536 35.9079 14.8409 35.9079 13.8182C35.9079 12.8026 35.7623 11.9006 35.4711 11.1122C35.1799 10.3239 34.7467 9.70597 34.1714 9.25852C33.5961 8.81108 32.8788 8.58736 32.0194 8.58736C31.1955 8.58736 30.496 8.80398 29.9207 9.23722C29.3454 9.67045 28.9086 10.2777 28.6103 11.0589C28.3191 11.8402 28.1735 12.7599 28.1735 13.8182Z" fill="#D4D4D8"/></svg>`,
    label: "Text block",
    category: "Layout",
    content: {
      type: "text",
      tagName: "span",
      content: "Insert your text here",
    },
    select: true,
  },
  {
    id: "link",
    media: `<svg width="31" height="15" viewBox="0 0 31 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.6667 1H23.6667C24.5421 1 25.4091 1.17244 26.2179 1.50747C27.0267 1.8425 27.7617 2.33356 28.3807 2.95262C28.9998 3.57168 29.4908 4.30661 29.8259 5.11544C30.1609 5.92428 30.3333 6.79119 30.3333 7.66667C30.3333 8.54215 30.1609 9.40905 29.8259 10.2179C29.4908 11.0267 28.9998 11.7617 28.3807 12.3807C27.7617 12.9998 27.0267 13.4908 26.2179 13.8259C25.4091 14.1609 24.5421 14.3333 23.6667 14.3333H19.6667M11.6667 14.3333H7.66667C6.79119 14.3333 5.92428 14.1609 5.11544 13.8259C4.30661 13.4908 3.57168 12.9998 2.95262 12.3807C1.70238 11.1305 1 9.43478 1 7.66667C1 5.89856 1.70238 4.20286 2.95262 2.95262C4.20286 1.70238 5.89856 1 7.66667 1H11.6667M10.334 7.6665H21.0007" stroke="#D4D4D8" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
    label: "Link",
    category: "Layout",
    content: {
      type: "link",
      traits: ["id"],
      attributes: { href: "https://www.codejet.ai/" },
    },
    select: true,
  },
  {
    id: "link-block",
    media: `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="39" height="39" rx="3.5" fill="#27272A"/><rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="#71717A"/><path class="light-stroke" d="M23 15H26C26.6566 15 27.3068 15.1293 27.9134 15.3806C28.52 15.6319 29.0712 16.0002 29.5355 16.4645C29.9998 16.9288 30.3681 17.48 30.6194 18.0866C30.8707 18.6932 31 19.3434 31 20C31 20.6566 30.8707 21.3068 30.6194 21.9134C30.3681 22.52 29.9998 23.0712 29.5355 23.5355C29.0712 23.9998 28.52 24.3681 27.9134 24.6194C27.3068 24.8707 26.6566 25 26 25H23M17 25H14C13.3434 25 12.6932 24.8707 12.0866 24.6194C11.48 24.3681 10.9288 23.9998 10.4645 23.5355C9.52678 22.5979 9 21.3261 9 20C9 18.6739 9.52678 17.4021 10.4645 16.4645C11.4021 15.5268 12.6739 15 14 15H17M16 20H24" stroke="#D4D4D8" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
    label: "Link",
    category: "Layout",
    content: {
      type: "link-box",
      components: [{ type: "text", tagName: "span", content: "Link Box" }],
    },
    select: true,
  },
  {
    id: "Form",
    media: `<svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="light-fill" d="M0 30C0 28.8954 0.895431 28 2 28H38C39.1046 28 40 28.8954 40 30V34C40 35.1046 39.1046 36 38 36H2C0.895431 36 0 35.1046 0 34V30Z" fill="#D4D4D8"/><path d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H38C38.8284 0.5 39.5 1.17157 39.5 2V8C39.5 8.82843 38.8284 9.5 38 9.5H2C1.17157 9.5 0.5 8.82843 0.5 8V2Z" fill="#27272A" stroke="#71717A"/><path d="M0.5 16C0.5 15.1716 1.17157 14.5 2 14.5H38C38.8284 14.5 39.5 15.1716 39.5 16V22C39.5 22.8284 38.8284 23.5 38 23.5H2C1.17157 23.5 0.5 22.8284 0.5 22V16Z" fill="#27272A" stroke="#71717A"/></svg>`,
    label: "Form",
    category: "Forms",
    content: {
      type: "form",
      components: [
        {
          type: "label",
          content: "Name",
        },
        {
          type: "input",
          attributes: { placeholder: "Enter your name" },
        },
        {
          type: "checkbox",
        },
        {
          type: "button",
          components: [{ type: "text", tagName: "span", content: "Submit" }],
        },
      ],
    },
    select: true,
  },
  {
    id: "Input",
    media: `<svg width="40" height="16" viewBox="0 0 40 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H38C38.8284 0.5 39.5 1.17157 39.5 2V14C39.5 14.8284 38.8284 15.5 38 15.5H2C1.17157 15.5 0.5 14.8284 0.5 14V2Z" fill="#27272A"/><path d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H38C38.8284 0.5 39.5 1.17157 39.5 2V14C39.5 14.8284 38.8284 15.5 38 15.5H2C1.17157 15.5 0.5 14.8284 0.5 14V2Z" stroke="#71717A"/><line class="light-stroke" x1="4.5" y1="4.5" x2="4.5" y2="11.5" stroke="#D4D4D8" stroke-linecap="round"/></svg>`,
    label: "Input",
    category: "Forms",
    content: {
      tag: "div",
      style: { display: "flex", "flex-direction": "column" },
      components: [
        {
          type: "label",
          content: "Name",
        },
        {
          type: "input",
          attributes: { placeholder: "Enter your name" },
        },
      ],
    },
    select: true,
  },
  {
    id: "Text area",
    media: `<svg width="46" height="29" viewBox="0 0 46 29" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="45" height="28" rx="3.5" fill="#27272A"/><rect x="0.5" y="0.5" width="45" height="28" rx="3.5" stroke="#71717A"/><line class="light-stroke" x1="4.5" y1="4.5" x2="4.5" y2="9.5" stroke="#D4D4D8" stroke-linecap="round"/><path class="primary-fill" d="M38.2071 26H42.5C42.7761 26 43 25.7761 43 25.5V21.2071C43 20.7617 42.4614 20.5386 42.1464 20.8536L37.8536 25.1464C37.5386 25.4614 37.7617 26 38.2071 26Z" fill="#71717A"/></svg>`,
    label: "Text area",
    category: "Forms",
    content: {
      type: "textfield",
    },
    select: true,
  },
  {
    id: "Select",
    media: `<svg width="40" height="16" viewBox="0 0 40 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H38C38.8284 0.5 39.5 1.17157 39.5 2V14C39.5 14.8284 38.8284 15.5 38 15.5H2C1.17157 15.5 0.5 14.8284 0.5 14V2Z" fill="#27272A"/><path d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H38C38.8284 0.5 39.5 1.17157 39.5 2V14C39.5 14.8284 38.8284 15.5 38 15.5H2C1.17157 15.5 0.5 14.8284 0.5 14V2Z" stroke="#71717A"/><path class="light-stroke" d="M8 8H22" stroke="#D4D4D8" stroke-linecap="round"/><path d="M29 7L31 9L33 7" stroke="#71717A" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
    label: "Select",
    category: "Forms",
    content: {
      type: "select",
      components: [
        {
          type: "option",
          tagName: "option",
          content: "Option 1",
          attributes: { value: "option1" },
        },
        {
          type: "option",
          tagName: "option",
          content: "Option 2",
          attributes: { value: "option2" },
        },
      ],
    },
    select: true,
  },
  {
    id: "Button",
    media: `<svg width="40" height="16" viewBox="0 0 40 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="light-fill" d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V14C40 15.1046 39.1046 16 38 16H2C0.895431 16 0 15.1046 0 14V2Z" fill="#D4D4D8"/><path d="M6 8H34" stroke="#27272A" stroke-linecap="round"/></svg>`,
    label: "Button",
    category: "Forms",
    content: {
      type: "button",
      traits: ["data-redirect", "id"],
      components: [
        {
          type: "text",
          tagName: "span",
          content: "Click me!",
          classes: ["cj-button__text"],
        },
      ],
    },
    select: true,
  },
  {
    id: "Label",
    media: `<svg width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="light-fill" d="M0 2C0 0.89543 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V4C24 5.10457 23.1046 6 22 6H2C0.89543 6 0 5.10457 0 4V2Z" fill="#D4D4D8"/><path class="dark-fill" d="M0.5 10C0.5 9.17157 1.17157 8.5 2 8.5H38C38.8284 8.5 39.5 9.17157 39.5 10V22C39.5 22.8284 38.8284 23.5 38 23.5H2C1.17157 23.5 0.5 22.8284 0.5 22V10Z" fill="#27272A"/><path d="M0.5 10C0.5 9.17157 1.17157 8.5 2 8.5H38C38.8284 8.5 39.5 9.17157 39.5 10V22C39.5 22.8284 38.8284 23.5 38 23.5H2C1.17157 23.5 0.5 22.8284 0.5 22V10Z" stroke="#71717A"/><line class="light-stroke" x1="4.5" y1="12.5" x2="4.5" y2="19.5" stroke="#D4D4D8" stroke-linecap="round"/></svg>`,
    label: "Label",
    category: "Forms",
    content: {
      type: "label",
      content: "Label",
    },
    select: true,
  },
  {
    id: "Checkbox",
    media: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="#27272A"/><rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#71717A"/><path class="light-stroke" d="M17.3334 8.33331L10.0001 15.6666L6.66675 12.3333" stroke="#D4D4D8" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
    label: "Checkbox",
    category: "Forms",
    content: {
      type: "checkbox",
    },
    select: true,
  },
  {
    id: "Radio",
    media: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#27272A"/><rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#71717A"/><circle class="light-fill" cx="12" cy="12" r="5" fill="#D4D4D8"/></svg>`,
    label: "Radio button",
    category: "Forms",
    content: {
      type: "radio",
    },
    select: true,
  },
  {
    id: "Navbar",
    media: `<svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H46C46.8284 0.5 47.5 1.17157 47.5 2V14C47.5 14.8284 46.8284 15.5 46 15.5H2C1.17157 15.5 0.5 14.8284 0.5 14V2Z" fill="#27272A"/><path d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H46C46.8284 0.5 47.5 1.17157 47.5 2V14C47.5 14.8284 46.8284 15.5 46 15.5H2C1.17157 15.5 0.5 14.8284 0.5 14V2Z" stroke="#71717A"/><rect class="light-fill" x="4" y="4" width="8" height="8" rx="2" fill="#D4D4D8"/><path class="light-stroke" d="M40 5H44M40 8H44M40 11H44" stroke="#D4D4D8" stroke-linecap="round"/></svg>`,
    label: "Navbar",
    category: "Layout",
    content: {
      type: "cjNavbar",
      components: [
        {
          type: "link",
          content: "Link",
        },
        {
          tagName: "nav",
          classes: ["cj-navbar__nav"],
          components: [
            {
              type: "link",
              content: "Home",
            },
            {
              type: "link",
              content: "About",
            },
            {
              type: "link",
              content: "Contact",
            },
          ],
        },
      ],
    },
    select: true,
  },
];
