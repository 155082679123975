import type { SVGProps } from "react";

export const FacebookLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_20304_1278)">
      <path
        d="M12.0502 19.9998V10.8769H15.1124L15.5709 7.32153H12.0502V5.05155C12.0502 4.02221 12.336 3.32067 13.8123 3.32067L15.695 3.3198V0.139942C15.3692 0.0967718 14.2517 0 12.9515 0C10.2371 0 8.37873 1.65681 8.37873 4.69962V7.32163H5.30859V10.877H8.37864V19.9999L12.0502 19.9998Z"
        fill="#3C5A9A"
      />
    </g>
    <defs>
      <clipPath id="clip0_20304_1278">
        <rect width="20" height="20" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
