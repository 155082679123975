import { createMachine, type EventObject } from "xstate";
import { Templates } from "../../services/Templates/Templates.ts";
import { routerInstance } from "../router/router.instance.ts";
import { templatesService } from "../templates/templates.instance.ts";

export interface CustomEvent<T> extends EventObject {
  data: T;
}

export const unauthorizedTemplatesMachineInit = (service: Templates) =>
  createMachine(
    {
      predictableActionArguments: true,
      context: {
        service,
        isProjectSettings: false,
      },
      id: "TEMPLATES_ONBOARDING",
      initial: "START",
      states: {
        START: {
          on: {
            FETCH_TEMPLATES: "FETCH_TEMPLATES",
          },
        },

        FETCH_TEMPLATES: {
          invoke: {
            src: "fetchTemplates",
            onDone: {
              target: "AWAITING_TEMPLATE_SELECTION",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        AWAITING_TEMPLATE_SELECTION: {
          entry: "notifyComponent",
          on: {
            SELECT_TEMPLATE: "SELECT_TEMPLATE",
            PREVIEW_TEMPLATE: "PREVIEW_TEMPLATE",
          },
        },

        PREVIEW_TEMPLATE: {
          invoke: {
            src: "previewTemplate",
            onDone: {
              target: "FINISH",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        SELECT_TEMPLATE: {
          invoke: {
            src: "selectProject",
            onDone: {
              target: "FINISH",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        FINISH: {
          on: {
            FETCH_TEMPLATES: "FETCH_TEMPLATES",
            SELECT_TEMPLATE: "SELECT_TEMPLATE",
            PREVIEW_TEMPLATE: "PREVIEW_TEMPLATE",
          },
        },

        ERROR: {
          entry: () => console.log("ERROR"),
        },
      },
    },
    {
      actions: {
        notifyComponent: (context) => context.service.notifySubscribers(),
      },
      services: {
        selectProject: async (_, event) => {
          return await new Promise<void>((resolve) => {
            service.setActiveProject(event.projectId);
            resolve();
          });
        },

        fetchTemplates: () => {
          return new Promise<void>((resolve, reject) => {
            service
              .getTemplates()
              .then((data) => {
                service.state = data;
                resolve();
              })
              .catch((error) => reject(new Error(error)));
          });
        },

        previewTemplate: async (_, event) => {
          return await new Promise<void>((resolve) => {
            // As tempallates already fetched, we can simlpy set the state and redirect to the editor
            templatesService.state = service.state;
            templatesService.setActiveProject(event.payload.projectId);
            routerInstance.send("ALPHA_O_TEMPLATES_UNAUTHORIZED");

            resolve();
          });
        },
      },
    }
  );
