import { createMachine, assign, type EventObject } from "xstate";
import { UserService } from "../../services/UserService/UserService.ts";
import { Projects } from "../../services/Projects/Projects.ts";
import { dashboardInstance } from "../dashboard/dashboard.instance.ts";

export interface CustomEvent<T> extends EventObject {
  data: T;
}

export const projectsMachineInit = (service: Projects) =>
  createMachine(
    {
      predictableActionArguments: true,
      context: {
        service,
        isProjectSettings: false,
      },
      id: "PROJECTS",
      initial: "START",
      states: {
        START: {
          on: {
            FETCH_PROJECTS: "FETCH_PROJECTS",
          },
        },

        FETCH_PROJECTS: {
          invoke: {
            src: "fetchProjects",
            onDone: {
              target: "NOTIFY_COMPONENT",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        NOTIFY_COMPONENT: {
          invoke: {
            src: "notifyComponent",
            onDone: {
              target: "AWAITING_PROJECT_SELECTION",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        AWAITING_PROJECT_SELECTION: {
          on: {
            SELECT_PROJECT: "SELECT_PROJECT",
            PROJECT_SETTINGS: "PROJECT_SETTINGS",
            PROJECT_SETTINGS_PLANS: "PROJECT_SETTINGS_PLANS",
          },
        },

        SELECT_PROJECT: {
          entry: "setIsNoProjectSettings",
          invoke: {
            src: "selectProject",
            // onDone: {
            //   target: "FETCH_HTML",
            // },
            onDone: [
              { target: "REDIRECT_TO_PROJECT_SETTINGS", cond: "isProjectSettings" },
              { target: "REDIRECT_TO_ALPHAO" },
            ],
            onError: {
              target: "ERROR",
            },
          },
        },

        PROJECT_SETTINGS: {
          entry: "setIsProjectSettings",
          invoke: {
            src: "selectProject",
            onDone: {
              target: "REDIRECT_TO_PROJECT_SETTINGS",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        PROJECT_SETTINGS_PLANS: {
          entry: "setIsProjectSettings",
          invoke: {
            src: "selectProject",
            onDone: {
              target: "REDIRECT_TO_PROJECT_SETTINGS_PLANS",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        // FETCH_HTML: {
        //   invoke: {
        //     src: "fetchHtml",
        //     onDone: {
        //       target: "FETCH_VARIABLES",
        //     },
        //     onError: {
        //       target: "ERROR",
        //     },
        //   },
        // },

        // FETCH_VARIABLES: {
        //   invoke: {
        //     src: "fetchVariables",
        //     onDone: [{ target: "FETCH_CSS" }],
        //     onError: {
        //       target: "ERROR",
        //     },
        //   },
        // },

        // FETCH_CSS: {
        //   invoke: {
        //     src: "fetchCss",
        //     onDone: [
        //       { target: "REDIRECT_TO_PROJECT_SETTINGS", cond: "isProjectSettings" },
        //       { target: "REDIRECT_TO_ALPHAO" },
        //     ],
        //     onError: {
        //       target: "ERROR",
        //     },
        //   },
        // },

        REDIRECT_TO_ALPHAO: {
          invoke: {
            src: "redirectToAlphaO",
            onDone: {
              target: "FINISH",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        REDIRECT_TO_PROJECT_SETTINGS: {
          invoke: {
            src: "redirectToProjectSettings",
            onDone: {
              target: "FINISH",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        REDIRECT_TO_PROJECT_SETTINGS_PLANS: {
          invoke: {
            src: "redirectToProjectSettingsPlans",
            onDone: {
              target: "FINISH",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        FINISH: {
          on: {
            FETCH_PROJECTS: "FETCH_PROJECTS",
            SELECT_PROJECT: "SELECT_PROJECT",
            PROJECT_SETTINGS: "PROJECT_SETTINGS",
            PROJECT_SETTINGS_PLANS: "PROJECT_SETTINGS_PLANS",
          },
        },

        ERROR: {
          entry: () => console.log("error"),
        },
      },
    },
    {
      guards: {
        isProjectSettings: (ctx) => ctx.isProjectSettings,
      },
      actions: {
        setIsProjectSettings: assign(() => {
          return { isProjectSettings: true };
        }),
        setIsNoProjectSettings: assign(() => ({ isProjectSettings: false })),
      },
      services: {
        selectProject: async (_, event) => {
          return await new Promise<void>((resolve) => {
            service.setActiveProject(event.projectId);
            resolve();
          });
        },

        notifyComponent: async () => {
          return await new Promise<void>((resolve) => {
            service.notifySubscribers();
            resolve();
          });
        },

        fetchProjects: () => {
          return new Promise<void>((resolve, reject) => {
            const userId = UserService.getInstance().getData()?.USER_ID;
            if (!userId) {
              service.state = [];
              return resolve();
            }
            if (userId) {
              service
                .getProjectsBy(userId)
                .then((data) => {
                  service.state = data;
                  resolve();
                })
                .catch((error) => reject(new Error(error)));
            } else {
              reject(new Error("No USER ID found"));
            }
          });
        },

        // fetchHtml: () => {
        //   return new Promise<void>((resolve, reject) => {
        //     const activeProject = service.getActiveProject();
        //     if (activeProject) {
        //       service
        //         .getHtmlByProjectId(activeProject.projectId)
        //         .then((html) => {
        //           activeProject.html = html;
        //           activeProject.projectHead =
        //             activeProject.html.match(/<html>[\s\S]*<head>[\s\S]*<\/head>/)?.[0] || "<html><head></head>";
        //           activeProject.projectCSSResetLink = activeProject.html.match(/<link.*?reset\.css.*?>/)?.[0] || "";
        //           activeProject.projectBody = activeProject.html.match(/<body>[\s\S]*<\/body>/)?.[0] || "<body></body>";
        //           activeProject.projectCloseTag = "\n</html>";
        //           resolve();
        //         })
        //         .catch((error) => reject(new Error(error)));
        //     } else {
        //       reject(new Error("No active project  in fetchHTML"));
        //     }
        //   });
        // },

        // fetchCss: () => {
        //   return new Promise<void>((resolve, reject) => {
        //     const activeProject = service.getActiveProject();
        //     if (activeProject) {
        //       service
        //         .getCssByProjectId(activeProject.projectId)
        //         .then((css) => {
        //           activeProject.css = css || "";
        //           resolve();
        //         })
        //         .catch((error) => reject(new Error(error)));
        //     } else {
        //       reject(new Error("No active project in fetchCSS"));
        //     }
        //   });
        // },

        // fetchVariables: () => {
        //   return new Promise<void>((resolve, reject) => {
        //     const activeProject = service.getActiveProject();
        //     if (activeProject) {
        //       service
        //         .getVariablesByProjectId(activeProject.projectId)
        //         .then((variables) => {
        //           activeProject.variables = variables;
        //           resolve();
        //         })
        //         .catch((error) => reject(new Error(error)));
        //     } else {
        //       reject(new Error("No active project in fetchCSS"));
        //     }
        //   });
        // },

        redirectToAlphaO: async () => {
          return await new Promise<void>((resolve) => {
            dashboardInstance.send("ALPHA_O");
            resolve();
          });
        },

        redirectToProjectSettings: async () => {
          return await new Promise<void>((resolve) => {
            dashboardInstance.send("PROJECT_SETTINGS");
            resolve();
          });
        },

        redirectToProjectSettingsPlans: async () => {
          return await new Promise<void>((resolve) => {
            dashboardInstance.send("PROJECT_SETTINGS_PLANS");
            resolve();
          });
        },
      },
    }
  );
