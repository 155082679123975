import { Product } from "../../../../services/Products/Products";

export const product1: Product = {
  priceId: import.meta.env.VITE_TEMPLATE_CUSTOMIZED_BY_OUR_EXPERTS_ID,
  type: "oneOffProduct",
  productType: "customizedByExperts",
  price: 49,
  meta: {
    name: "Expert assistance - Template customisation",
    billingPeriodDescription: "Per implementation",
  },
};

export const cardData = [
  {
    header: "Get your template customised by our experts in under 24 hours!",
    subheader: "One of our experts will reach out to you after your order is placed.",
    priceDescription: "/per implementation",
    ...product1,
  },
];
