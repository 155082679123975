import { ButtonNew } from "../../../../atoms/ButtonNew";

import { FigmaLogo } from "../../../../../../assets/svg/figma_logo";
import { FacebookLogo } from "../../../../../../assets/svg/facebook_logo";
import { GoogleLogo } from "../../../../../../assets/svg/google_logo";

import type { TSignInSection } from "./SignInSection.types";

import "./SignInSection.css";

export const SignInSection: TSignInSection = () => {
  return (
    <>
      <div className="sign-in__header">
        <h2 className="sign-in__typography">Create an account</h2>
        <h3 className="sign-in__typography">You need to have an account to make a purchase.</h3>
      </div>
      <div className="sign-in__signin-container">
        <ButtonNew
          className="sign-in__signin-button"
          iconStart={<GoogleLogo />}
          href={"api/auth/google/register"}
          target="_self"
        >
          Sign in with Google
        </ButtonNew>
        <ButtonNew className="sign-in__signin-button" iconStart={<FacebookLogo />}>
          Sign in with Facebook
        </ButtonNew>
        <ButtonNew className="sign-in__signin-button" iconStart={<FigmaLogo />}>
          Sign in with Figma
        </ButtonNew>
        <div className="sign-in__signin-separator">
          <div className="sign-in__signin-separator__line"></div>
          <div className="codejet__typography sign-in__separator__or">or</div>
          <div className="sign-in__signin-separator__line"></div>
        </div>
        <ButtonNew className="sign-in__signin-button">Sign in with your Email</ButtonNew>
      </div>
      <div className="sign-in__login-container">
        <div className="codejet__typography sign-in__login-desc">
          By creating an account, you agree to receive Clickjet emails, including marketing emails, and to our Terms of
          Service & Privacy Policy
        </div>
        <div className="codejet__typography sign-in__login-section">
          Already have an account? <a href="/">Login</a>
        </div>
      </div>
    </>
  );
};
