import { Avatar } from "../../../../Dashboard/atoms/Avatar";
import { ButtonNew } from "../../../../atoms/ButtonNew";

import { PenAndRuler } from "../../../../../../assets/svg/pen_and_ruler";
import CeoIcon from "../../../../../../assets/png/ceo-avatar.png";

import type { TPriceSection } from "./PriceSection.types";

import "./PriceSection.css";

export const PriceSection: TPriceSection = ({ openFormModal }) => {
  return (
    <div className="codejet__price-section">
      <div className="codejet__price-section__descritpion">
        <div className="codejet__avatar-container">
          <Avatar src={CeoIcon} className="codejet__avatar" />
        </div>
        <h2 className="codejet__price-section__heading">No technical skills? Let our experts help you!</h2>
        <h3 className="codejet__price-section__subheading">
          Let our experts build your website from scratch! Get a fully customized, professional design that aligns
          perfectly with your brand and vision, without the hassle
        </h3>
        <ButtonNew onClick={() => openFormModal()} className="codejet__price-section__button" orange>
          Let our experts create your website
        </ButtonNew>
      </div>
      <div className="codejet__price-section__offer">
        <PenAndRuler className="unauthorized-templates_from-scratch_icon" />
        <h2 className="codejet__price-section__offer-heading">Website from scratch</h2>
        <h3 className="codejet__price-section__offer-subheading">
          Let our experts create a customised project for you in under 24 hours!
        </h3>
        <div className="codejet__price-section__offer-price">
          <strong className="codejet__price-value">$99</strong>/per implementation
        </div>
      </div>
    </div>
  );
};
