import { useEffect, useState } from "react";

import { UseTemplateModalService as UseTemplateModalService } from "./UseTemplateModal.service";
import useOutsideClick from "../../../hooks/useOutsideClick/useOutsideClick";

import { Header } from "../../atoms/Header";
import { Offer } from "./atoms/Offer";
import { ButtonNew } from "../../atoms/ButtonNew";

import CeoIcon from "../../../../assets/png/ceo-avatar.png";

import { cardData } from "./data/data";

import { TUseTemplateModal } from "./UseTemplateModal.types";

import "./UseTemplateModal.css";

const useTemplateModalService = UseTemplateModalService.getInstance();

export const UseTemplateModal: TUseTemplateModal = ({ onContinue, onSelect }) => {
  const [_, setState] = useState(false);
  const isOpen = useTemplateModalService.state;
  const closeModal = () => useTemplateModalService.closeModal();
  const ref = useOutsideClick<HTMLDivElement>(closeModal);
  const [isOfferChoosed, setIsOfferChoosed] = useState(false);
  const product = cardData[0];
  const { header, subheader, priceDescription, type, productType, priceId, price, meta } = product;

  useEffect(() => {
    useTemplateModalService.subscribe(setState);
    return () => useTemplateModalService.unsubscribe(setState);
  }, []);

  const onClickHandler = () => {
    setIsOfferChoosed(false);
    onContinue();
    closeModal();
  };

  const onSelectHandler = () => {
    setIsOfferChoosed(!isOfferChoosed);
    onSelect({
      priceId,
      type,
      productType,
      price,
      meta,
    });
  };

  return (
    <div className="use-template-modal__wrapper">
      <div className={"use-template-modal__backdrop" + (isOpen ? " use-template-modal__backdrop--visible" : "")}>
        <div className={"use-template-modal" + (isOpen ? " use-template-modal__visible" : "")} ref={ref}>
          <Header
            iconSrc={CeoIcon}
            headingText="Let our experts customise your template!"
            subheadingText="Template tailored for your needs."
          />
          <Offer
            headingText={header}
            subheadingText={subheader}
            price={price}
            priceDescription={priceDescription}
            isOrangeBackground={isOfferChoosed}
            onClick={onSelectHandler}
          />
          {isOfferChoosed ? (
            <div className="use-template-modal__button-group">
              <ButtonNew className="form-modal__button" onClick={onClickHandler}>
                Skip
              </ButtonNew>
              <ButtonNew className="form-modal__button" onClick={onClickHandler} orange>
                Continue
              </ButtonNew>
            </div>
          ) : (
            <ButtonNew className="use-template-modal__skip-button" onClick={onClickHandler}>
              Skip
            </ButtonNew>
          )}
        </div>
      </div>
    </div>
  );
};
